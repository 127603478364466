<template>
    <div class="index">
        <!-- 预约咨询列表 -->
        <div class="dateItem">
            <div :class="isAll ? 'item active' : 'item'" @click="search(1)">
                <span>全部</span>
            </div>
            <!-- <div class="item ">
                <i class="el-icon-arrow-left"></i>
            </div> -->
            <div :class="item.status ? 'active item' : 'item'" v-for="item in TimeList" :key="item.id"
                @click="search(2, item)">
                <p>
                    <span>{{ item.day }}</span>
                    <br />
                    <span>{{ item.date }}</span>
                </p>
            </div>
            <!-- <div class="item">
                <p>
                    <span>周二</span>
                    <br />
                    <span>12.6</span>
                </p>
            </div> -->
        </div>
        <div :class="searchInfo.number ? 'active search' : 'search'" @click="searchNumber">
            <span>筛选有号</span>
        </div>
        <div class="container" v-if="dataList && dataList.length > 0">
            <div class="item" v-for="item in dataList" :key="item.id" @click="toDetail(item.userId)">
                <div class="left" v-if="item.portrait">
                    <img :src="item.portrait" alt="">
                </div>
                <div class="left" v-else>
                    <img src="../../assets/home/img.png" alt="">
                </div>
                <div class="right">
                    <p class="title">{{ item.nickname }}</p>
                    <p>{{ item.title }}</p>
                    <span>{{ item.memo }}</span>
                </div>
                <div class="img">
                    <img src="../../assets/home/hasnumber.png" alt="" v-if="item.number === true">
                    <img v-else src="../../assets/home/nonumber.png" alt="">
                </div>
            </div>

            
        </div>
        <div class="container" v-else>
            <span class="noConsult">
            暂无可选咨询师</span>
        </div>
    </div>
</template>

<script>
import { getRecordList } from '@/api/record'
export default {
    data() {
        return {
            isAll: true, // 是否选择的是全部
            isSearch: false, // 是否点击筛选有号
            TimeList: [], // 渲染的时间日期数组，
            DayList: [], // 星期数组
            dataList: [], //预约列表
            searchInfo: {
                number: false,
                date: null
            }
        }
    },
    methods: {
        toDetail(val) {
            this.$router.push({
                path: '/home/consultDetails',
                // query: {
                //     id: val
                // }
            })
            sessionStorage.setItem('consultInfo', JSON.stringify({consultId: val}))
        },
        // 获取预约咨询列表
        async getList() {
            console.log(this.searchInfo)
            const res = await getRecordList(this.searchInfo);
            console.log(res);
            if (res.code === 200) {
                this.dataList = res.data;
            }
        },
        // 按照时间搜索
        search(val1, val) {
            // console.log((new Date()).getFullYear())
            console.log(val)
            // 点击全选
            if (val1 === 1) {
                this.isAll = true;
                this.TimeList.forEach(item => {
                    item.status = false;
                })
                this.searchInfo.date = null;
            } else { // 选择日期
                val.status = true;
                this.isAll = false;
                this.TimeList.forEach(item => {
                    if (item.id !== val.id) {
                        item.status = false;
                        //    return {}
                    }
                })
                let year = (new Date()).getFullYear();
                this.searchInfo.date = year + '-' + val.date;
                // this.searchInfo.date = {}
            }
            
            this.getList();
        },
        // 点击筛选是否有号
        searchNumber() {
            this.searchInfo.number = !this.searchInfo.number;
            this.getList();
        },
        // 获取时间数组
        getTimeList() {
            var dateList = [];
            let startDate = new Date();
            let endDate = new Date();
            endDate.setDate(startDate.getDate() + 30);
            while ((endDate.getTime() - startDate.getTime()) >= 0) {
                let month = (startDate.getMonth() + 1).toString().length === 1 ? "0" + (startDate.getMonth() + 1).toString() : (startDate.getMonth() + 1);
                let day = startDate.getDate().toString().length === 1 ? "0" + startDate.getDate() : startDate.getDate();
                dateList.push(month + "-" + day);
                startDate.setDate(startDate.getDate() + 1);
            }
            //  = dateList
            dateList.forEach((item, index) => {
                this.TimeList.push({ date: item, day: this.DayList[index], id: index + 1, status: false })
            })

            // console.log(dateList)
        },
        setDate() {
            var now = new Date().getDay();
            // console.log(now)
            let list = [];
            let s = now;
            for (let i = 0; i < 31; i++) {
                // if()
                if (s === 8) {
                    s = 1
                }
                list.push(s);
                s++;
            }
            // console.log(list)
            this.DayList = list.map(item => {
                if (item === 1) {
                    return item = '周一'
                } else if (item === 2) {
                    return item = '周二'
                } else if (item === 3) {
                    return item = '周三'
                } else if (item === 4) {
                    return item = '周四'
                } else if (item === 5) {
                    return item = '周五'
                } else if (item === 6) {
                    return item = '周六'
                } else if (item === 7) {
                    return item = '周天'
                }
            })
        },
    },
    created() {
        this.setDate();
        this.getTimeList();
        this.getList();

    }
}
</script>

<style lang="scss" scoped>
.index {
    // padding: 15px 125px;
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;


    p {
        margin: 0;
    }

    .dateItem {
        // height: 60px;
        width: 100%;
        overflow-x: scroll;
        height: 80px;
        // box-sizing: border-box;
        display: flex;

        .item {
            // display: inline-block;
            box-sizing: border-box;
            width: 60px;
            height: 60px;
            background: #F2F2F2;
            border-radius: 4px;
            color: #7D7D7D;
            margin: 0 7px;
            font-size: 15px;
            text-align: center;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .active.item {
            background: var(--custom-color);
            color: white;
        }
    }

    .search {
        width: 100%;
        height: 50px;


        // background: #FFFFFF;
        border-radius: 25px;
        border: 1px solid #ECC2E6;
        text-align: center;
        color: var(--custom-color);
        cursor: pointer;
        box-sizing: border-box;

        span {
            font-size: 13px;
            line-height: 50px;
        }
    }

    .active.search {
        color: white;
        background: var(--custom-color);
    }


    .container {
        margin-top: 10px;
        .noConsult{
            font-size: 14px;
            padding-top: 30px;
            width: 100%;
            display: inline-block;
            text-align: center;
            color: var(--custom-color);
        }
        .item {
            position: relative;
            margin-top: 20px;
            height: 130px;
            padding: 0;
            border-radius: 19px;
            border: 1px solid #E7E7E7;
            box-sizing: border-box;
            display: flex;
            cursor: pointer;

            .left {
                img {
                    height: 130px;
                    width: 130px;
                    border-radius: 15px;
                }
            }

            .right {
                margin-left: 10px;
                padding: 13px 0px;
                box-sizing: border-box;

                p {
                    font-size: 16px;
                    color: #303030;
                }

                .title {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                span {
                    margin-top: 5px;
                    font-size: 13px;
                    color: #7D7D7D;
                    display: -webkit-box;
                    /*必须结合的属性,将对象作为弹性伸缩盒子模型显示*/
                    -webkit-line-clamp: 2;
                    /*设置多少行*/
                    -webkit-box-orient: vertical;
                    /*必须结合的属性,设置或检索伸缩盒对象的子元素的排列方式*/
                    overflow: hidden;
                    /*文本会被修剪，并且剩余的部分不可见*/
                    text-overflow: ellipsis;
                }
            }

            .img {
                position: absolute;
                right: 0;
                top: 0px;

                img {
                    width: 56px;
                }
            }
        }
    }
}

@media screen and (min-width: 751PX) {
    .index {
        padding: 20px 125px;

        .search {
            margin-top: 20px;

            span {
                font-size: 16px;
            }
        }

        .container {
            .item {
                .right {
                    .title {
                        margin-bottom: 10px;
                    }

                    span {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 750PX) {
    .index {
        padding: 20px;

        .search {
            margin-top: 5px;
        }

        .dateItem {
            height: 70px;

            .item {
                width: 50px;
                height: 50px;

                span {
                    font-size: 12px;
                }
            }
        }


    }
}
</style>